import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

export default class ContactBar extends HTMLElement {
  constructor() {
    super();
    this.trigger = this.querySelector('.contact-bar-trigger');
    this.content = this.querySelector('.contact-bar-content');
    this.bar = this.querySelector('.contact-bar');
    this.header = document.querySelector('.header');
    this.scrollTriggerIds = [];

    if (this.trigger) {
      this.trigger.addEventListener('click', (event) => {
        event.preventDefault();
        this.toggle();
      });
    }

    window.addEventListener('scroll', () => {
      if (!this.collapsed && !this.matches(':hover')) {
        this.collapsed = true;
      }
    });
  }

  toggle() {
    this.collapsed = !this.collapsed;
  }

  static get observedAttributes() {
    return ['collapsed'];
  }

  set collapsed(val) {
    if (val) {
      this.setAttribute('collapsed', '');
      this.trigger.classList.add('after:animate-shake');
      this.setAttribute('aria-expanded', 'false');
    }
    else {
      this.removeAttribute('collapsed');
      this.setAttribute('aria-expanded', 'true');
      this.trigger.classList.remove('after:animate-shake');
    }
  }

  get collapsed() {
    return this.hasAttribute('collapsed');
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name == 'collapsed') {
      this.classList.toggle('collapsed', this.collapsed);
      this.trigger.innerText = this.collapsed ? this.trigger.dataset.labelDefault : this.trigger.dataset.labelActive;
    }
  }

  connectedCallback() {
    const buttonsAppointment = document.querySelectorAll('.button-appointment');
    buttonsAppointment.forEach((buttonAppointment) => {
      this.scrollTriggerIds.push('st-' + (Math.random() + 1).toString(36).substring(5));
      const st = ScrollTrigger.create({
        trigger: buttonAppointment,
        id: this.scrollTriggerId,
        start: 'top bottom',
        end: () => `bottom ${this.header.clientHeight}`,
        onEnter: () => {
          this.bar.classList.add('translate-y-full');
          this.bar.classList.remove('translate-y-0');
        },
        onEnterBack: () => {
          this.bar.classList.add('translate-y-full');
          this.bar.classList.remove('translate-y-0');
        },
        onLeave: () => {
          this.bar.classList.remove('translate-y-full');
          this.bar.classList.add('translate-y-0');
        },
        onLeaveBack: () => {
          this.bar.classList.remove('translate-y-full');
          this.bar.classList.add('translate-y-0');
        },
      });
    })
  }

  disconnectedCallback() {
    this.scrollTriggerIds.forEach(scrollTriggerId => {
      if (ScrollTrigger.getById(scrollTriggerId)) {
        ScrollTrigger.getById(scrollTriggerId).kill(true);
      }
    });
    this.scrollTriggerIds = [];
  }

}

// Define the new web component
if ('customElements' in window) {
  customElements.define('contact-bar', ContactBar);
}
